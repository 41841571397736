import React, { Component } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import TabBar from "./components/TabBar";
import TabBarItem from "./components/TabBarItem";
import { Pages, WalletPages } from "./Pages";
import Modals from "./components/Modals";
import { showModal } from "./state/actions/Modals";
import ModalTypes from "./constants/ModalTypes";
import { store } from "./state";
import Router from "./services/Router";
import Wallet from "./services/Wallet";
import Alerts from "./services/Alerts";
import { copyToClipboard } from "./utils/Utils";
import { logout } from "./state/actions/Wallet";
import apiService from "./services/Api";
import { withTranslation } from "react-i18next";
import config from "./Config";
import { Link } from "react-router-dom";
import Web3 from 'web3';
import pnc20 from "./pnc20";
import { t } from "i18next";
import TokensPage from "./pages/TokensPage";
const rpcURL = config.rpcUrl
const web3 = new Web3(rpcURL);


let balance = 0;

class WalletTabBar extends Component {
  constructor() {
    super();

    this.logout = this.logout.bind(this);
  }
  componentDidMount() { }

  logout() {

    console.clear();
    Wallet.setStakeType(null);
    Wallet.setStakeAddress(null);
    store.dispatch(logout());
  }

  render() {
    const { t } = this.props;

    return (
      <TabBar className="TabBar1 ">
        {/* <img className="NavBar__logo" src={'/img/logo/pando_wallet_logo@2x.svg'} /> */}

        <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`Dashboard`)}
          href="/wallet/dashboard"
          normalIconUrl="/img/nav/dash.svg"
          activeIconUrl="/img/nav/dash-act.svg"
        />
        {/* <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`Tokens`)}
          href="/wallet/tokens"
          normalIconUrl="/img/tab-bar/wallet@2x.svg"
          activeIconUrl="/img/tab-bar/WALLET2.svg"
        /> */}
        <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`REWARDS`)}
          href="/wallet/rewards"
          normalIconUrl="/img/nav/reward.svg"
          activeIconUrl="/img/nav/reward-act.svg"
        />

        <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`Transaction`)}
          href="/wallet/transaction"
          normalIconUrl="/img/nav/tran.svg"
          activeIconUrl="/img/nav/tran-act.svg"
        />

        <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`STAKES`)}
          href="/wallet/stakes"
          normalIconUrl="/img/nav/stack.svg"
          activeIconUrl="/img/nav/stack-act.svg"
        />

        {/* <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`Tokens`)}
          href="/wallet/tokens"
          normalIconUrl="/img/nav/token.svg"
          activeIconUrl="/img/nav/token-act.svg"
        /> */}

        {/* <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`NFT`)}
          href="/wallet/nft-page"
          normalIconUrl="/img/nav/nft.svg"
          activeIconUrl="/img/nav/nft-act.svg"
        /> */}

        <TabBarItem
          isMobile={!this.props.isMobile}
          title={t(`CONTRACT`)}
          href="/wallet/contract"
          normalIconUrl="/img/nav/cont.svg"
          activeIconUrl="/img/nav/cont-act.svg"
        />

        <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`SETTINGS`)}
          href="/wallet/settings"
          normalIconUrl="/img/nav/stting.svg"
          activeIconUrl="/img/nav/stting-act.svg"
        />
        <TabBarItem
          isMobile={this.props.isMobile}
          title={t(`LOG_OUT`)}
          onClick={this.logout}
          normalIconUrl="/img/nav/log.svg"
        />
      </TabBar>
    );
  }
}

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balance: 0,
    };
  }

  liftState = (state) => {
    this.setState(balance);
  };
  componentDidMount() {
    Router.setHistory(this.props.history);
  }
  componentWillUnmount() { }

  render() {
    let address = Wallet.getWalletAddress();
    let urlPath = window.location.pathname.split("/");
    // console.log(urlPath[1]);
    return address ? (
      <div className="App home login">
        <NavBar centered={address === null} />
        <Pages />
        <Modals />
      </div>
    ) : urlPath[1] != "home" && address === null ? (
      <div className="App login">
        <NavBar centered={address === null} />
        <Pages />
        <Modals />
      </div>
    ) : urlPath[1] == "home" ? (
      <div className="App home">
        <NavBar centered={address === null} />
        <Pages />
        <Modals />
      </div>
    ) : (
      console.log("url not match")
    );
  }
}

class WalletApp extends Component {
  constructor() {
    super();
    this.onSendClick = this.onSendClick.bind(this);
    this.onReceiveClick = this.onReceiveClick.bind(this);
    this.state = {
      balance: 0,
      stakeDetails: { totalStakeAmount: 0, totalStake: 0 },
      transactionData: "",
      address: null,
      rametronStake: [],
      tokenLoading: false,
      stakeLoading: false,
      width: window.innerWidth,
      stackes: [],
      item: [],
      intervalId: "",
      transactionData: [],
      transactionLoading: false,
      transactionDataMeta: [],
      latestPrice: "",
      isUpdate: false,
      isloadtoken: false
    };
    this.interval = null;
  }
  onSendClick() {
    store.dispatch(
      showModal({
        type: ModalTypes.SEND,
      })
    );
  }

  onReceiveClick() {
    store.dispatch(
      showModal({
        type: ModalTypes.RECEIVE,
      })
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    Router.setHistory(this.props.history);
    this.setState({ address: Wallet.getWalletAddress() });
    this.loadData();
  }

  loadData() {
    this.setState({ isloadtoken: false })
    let address = Wallet.getWalletAddress();
    if (address) {
      let arr = config.pubaddr;
      for (let i of config.pubaddr) {
        if (i.toLocaleLowerCase() == address.toLocaleLowerCase()) {
          // window.localStorage.clear();
           console.clear();
          store.dispatch(logout());
          return;
        }
      }


      apiService.priceapi().then(
        (resp) => {

          let resData = resp.lBankPrice[0].latestPrice;
          this.setState({ latestPrice: resData });
          // console.log(this.state.latestPrice)

        }
      );

      this.getBalance();
      this.getStakeData();
      setTimeout(() => {
        this.setState({ isloadtoken: true })
      })
      this.getTranscton();

    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    clearInterval(this.interval);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  getBalance() {
    this.setState({ tokenLoading: true });
    let address = Wallet.getWalletAddress();
    apiService
      .getWalletBalance(address)
      .then((res) => {
        this.setState({ tokenLoading: false });

        if (res.body && res.body.balance) {
          res.body.balance.Test = "1";
          const newObj = Object.fromEntries(
            Object.entries(res.body.balance).map(([k, v]) => [
              k.toLowerCase(),
              v,
            ])
          );
          this.setState({
            balance: Number(newObj.ptxwei / 1000000000000000000),
          });
        } else {
          this.setState({ balance: 0 });
        }
      })
      .catch(() => {
        this.setState({ tokenLoading: false });
      });
  }

  getStakeData() {
    this.setState({ stakeLoading: true });
    apiService
      .fetchStakes(Wallet.getWalletAddress())
      .then((data) => {
        this.setState({ stackes: data.body.sourceRecords });
        let totalStake = 0;
        // let stakrle = 0
        for (let i of data.body.sourceRecords) {
          totalStake = totalStake + i.amount / 1000000000000000000;
          // stakrle++
        }
        apiService
          .getStakeType(Wallet.getWalletAddress())
          .then((his) => {
            // if (his.body && his.body.length) {
            // }
            this.setState({
              stakeDetails: {
                totalStakeAmount: totalStake.toFixed(2),
                totalStake: his.body.length,
              },
              stakeLoading: false,
            });
            this.setState({ stakeLoading: false });
          })
          .catch(() => {
            this.setState({ stakeLoading: false });
          });
      })
      .catch(() => {
        this.setState({ stakeLoading: false });
      });
  }
  copyAddress = () => {
    copyToClipboard(Wallet.getWalletAddress());
    Alerts.showSuccess(this.props.t(`YOUR_ADDRESS_HAS_BEEN_COPIED`));
  };
  calcualteAmount = (amount) => {
    if (isNaN(amount)) {
      return "";
    }
    return amount.toFixed(2);
  };
  calcualteSmallAmount = (amount) => {
    if (isNaN(amount)) {
      return "";
    }
    return amount.toFixed(2);
  };
  render() {
    const { width } = this.state;
    const isMobile = width <= 991;
    const { t } = this.props;
    const transvalue = this.state.item;
    let transval = [];
    transval.push(transvalue);
    // console.log("xy : ",transval)
    let walletva = this.state.balance.toFixed(4) * this.state.latestPrice;
    let walletvalue = walletva.toFixed(4);
    let address = Wallet.getWalletAddress();
    let urlval = window.location.pathname.split("/")[2];
    return (
      <div className="App WalletApp">
        <NavBar />
        <div className="container-flud">
          <div className="dashboard">
            <div className="row ">
              {/*Desktop Section starts Here*/}
              {!isMobile && (
                <div className="col-lg-2 side-nav for-wi3 ">
                  <WalletTabBar t={t} isMobile={!isMobile} />

                </div>
              )}
              {/*Desktop Section Ends here*/}
              {/*Mobile Section Starts here*/}
              {isMobile && (
                <WalletTabBar t={t} isMobile={isMobile} />
              )}
              {/*Mobile Section Ends here */}

              <div className="col-lg-10 fkju7">
                <div className="main-wrapper">
                  <div className="heading">{/* <h2>Dashboard</h2> */}</div>
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="custom-copy mob-dis">
                        <p onClick={this.copyAddress}>{t(`Wallet Id`)}:  {Wallet.getWalletAddress()}  <img src="/img/icons/copy1.svg"></img>

                        </p>

                      </div>

                      {address ? <h1 className="pageHeading">{urlval}

                        <img
                          src="/img/Path 716.svg"
                          onClick={() => this.loadData()}
                          style={{ cursor: "pointer" }}
                          className="imgrefeshinc" id="Refresh1"></img></h1> : ""}
                      <div className="dash-flex">

                        <div className="right-side-details">
                          <div className="custom-flex">
                            <div className="">
                              <img src="/img/icons/wallet.png"></img>
                            </div>
                            <p>
                              Wallet Balance <span>$ {walletvalue}</span>
                            </p>
                          </div>
                        </div>
                        <div className="right-side-details">
                          <div className="custom-flex">
                            <div className="">
                              <img src="/img/total-ptx.png"></img>
                            </div>
                            <p>
                              Current Price
                              <span>${this.state.latestPrice}</span>
                            </p>
                          </div>
                        </div>
                        <div className="right-side-details">
                          <div className="custom-flex">
                            <div className="">
                              {/* <img
                                src="/img/Path 716.svg"
                                onClick={() => this.loadData()}
                                style={{ cursor: "pointer" }}
                                className="imgrefeshinc" id="Refresh1"></img> */}

                              <img src="/img/icons/price.png"></img>
                            </div>
                            <p className="p-1">

                              {t(`PTX Available`)}
                              {this.state.tokenLoading ? (
                                <>
                                  <span>
                                    <i
                                      className="fa fa-spinner fa-spin fa-1x mr-2"
                                      aria-hidden="true"></i>{" "}
                                    Please wait ...
                                  </span>
                                </>
                              ) : (
                                <span
                                  style={{
                                    float: "none",
                                    color: "white"
                                  }}>
                                  {this.state.balance.toFixed(4)}
                                </span>
                              )}

                            </p>
                          </div>
                        </div>

                        <div className="right-side-details">
                          <div className="custom-flex">
                            <div className="">
                              <img src="/img/total-tran.png"></img>
                            </div>
                            <p>
                              Total transaction

                              <span className="innerValue">


                                {this.state.transactionData ?

                                  <b>
                                    {this.state.transactionData.length}
                                    {this.state.transactionData.length > 49 ?
                                      <b>
                                        +
                                      </b>
                                      : ''}
                                  </b>
                                  : ''}

                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="right-side-details">
                          <div className="custom-flex">
                            <div className="">
                              <img src="/img/total-stake.png"></img>
                            </div>
                            <p>
                              No. of stakes{" "}
                              <span className="innerValue">
                                {this.state.stakeLoading ? (
                                  <i
                                    className="fa fa-spinner fa-spin fa-1x ml-2"
                                    aria-hidden="true"></i>
                                ) : (
                                  this.state.stakeDetails.totalStake
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="right-side-details">
                          <div className="custom-flex">
                            <div className="">
                              <img src="/img/stake-amount.png"></img>
                            </div>
                            <p>
                              Total stake amt.{" "}
                              <span className="innerValue">
                                {this.state.stakeLoading ? (
                                  <i
                                    className="fa fa-spinner fa-spin fa-1x ml-2"
                                    aria-hidden="true"></i>
                                ) : (
                                  <>
                                    {this.state.stakeDetails.totalStakeAmount}{" "}
                                    PTX
                                  </>
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="custom-copy des-des">
                          <p>{t(`Wallet Id`)}:</p>
                          <a className="" onClick={this.copyAddress}>
                            <span>
                              {Wallet.getWalletAddress()}
                            </span>
                            <img src="/img/icons/copy1.svg"></img>
                          </a>
                        </div>
                      </div>






                      <div className="parentcontainer">
                        <WalletPages key={this.state.balance} t={t} />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="smallcontainer snd-rec">
                        <div className="tab-wrapper-send-recieve">
                          <TabBarItem
                            title={t(`SEND`)}
                            onClick={this.onSendClick}
                          />

                          <TabBarItem
                            title={t(`RECEIVE`)}
                            onClick={this.onReceiveClick}
                          />
                        </div>

                        <div className="sidecontainer">
                          <div className="trans-box">
                            {/* <div className="heading">
                              <h4>Transaction History</h4>
                              <Link to="/wallet/transaction">
                                <img src="/img/arrow-left.png"></img>
                              </Link>
                            </div> */}



                            {/* 
                            {this.state.transactionData.length ? (
                              this.state.transactionData.map((val, index) => {

                                if (index < 6) {

                                  return (
                                    <div className="trans-flex">
                                      <div className="lefty">
                                        <h5>
                                          {val.type == 7 ?
                                            <div className="tokness"><span>PNC-20</span>
                                           
                                            </div>
                                            : ''
                                          }
                                          <a
                                            className="custom-links"
                                            target="_blank"
                                            href={
                                              config.explorerFrontendURL +
                                              "/txs/" +
                                              val.hash
                                            }>
                                            {val?.hash.substring(0, 10)}....
                                          </a>
                                        </h5>
                                        <span>{val?.block_height}

                                        </span>
                                      </div>
                                      <div className="righty">
                                        <h5>
                                          {this.calcualteAmount(
                                            (val?.data?.outputs[0]?.coins?.ptxwei /
                                              1000000000000000000)
                                          )}

                                          {val?.type == 7 ?
                                            <b>
                                              {val?.symbol}
                                            </b>
                                            :
                                            <b>
                                              PTX
                                            </b>
                                          }

                                        </h5>
                                        <span>{val?.status}</span>
                                      </div>
                                    </div>
                                  );
                                }
                              })
                            ) : (
                             
                              <div className="trans-flex">
                                <h5>No transaction history found</h5>
                              </div>
                            )} */}


                            {
                              this.state.isloadtoken ?
                                <TokensPage></TokensPage>
                                : ''
                            }

                          </div>

                        </div>
                        <div className="explorer-img">
                          <a
                            href={"https://explorer.pandoproject.org/account/" + Wallet.getWalletAddress()}
                            target="_blank">
                            <img src="/img/icons/explorer.svg"></img>
                          </a>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modals />
            {/* <UnsupportedDevice /> */}
          </div>
        </div>
      </div>
    );
  }

  // async getSymbol() {
  //   let index = 0;
  //   for (let i of this.state.transactionData) {
  //     index++
  //     if (i.receipt && i.receipt.ContractAddress && index < 7) {
  //       try {
  //         let pandoContract = new web3.eth.Contract(pnc20.abi, i.receipt.ContractAddress);
  //         i.symbol = await pandoContract.methods.symbol().call();
  //         i.decimal = await pandoContract.methods.decimals().call();
  //         const data = await web3.eth.getTransaction(i.hash);
  //         if (data) {
  //           let str = data.input.substring(10)
  //           let decodeParam = await web3.eth.abi.decodeParameters(['address', 'uint256'], str)
  //           i.data.outputs[0].coins.ptxwei = (decodeParam['1'] * 1000000000000000000) / 10 ** i.decimal
  //         }

  //       }
  //       catch (err) {
  //         i.type = 2;
  //       }

  //     }

  //   }
  //   this.setState({ isUpdate: true })


  // }


  async getTranscton() {
    this.setState({ transactionLoading: true });
    let address = Wallet.getWalletAddress();
    await apiService
      .getTransactionHistory(address)
      .then((data) => {
        this.setState({ item: data });
        // data.json().then((res)=>{
        //   this.setState( {item: res} );
        // })

        if (data.body && data.body.length > 0) {
          let txbody = [];
          for (let i of data.body) {
            if (i.type == 2) {
              txbody.push(i);
            }
            // } else if (
            //   i.type == 7 && i.eth_tx_hash != "0x0000000000000000000000000000000000000000000000000000000000000000"
            // ) {
            //   i.data.inputs = [];
            //   i.data.outputs = [];
            //   i.data.inputs.push(i.data.from);
            //   i.data.outputs.push(i.data.to);
            //   i.data.outputs[0].coins = i.data.from.coins;
            //   // console.log()
            //   i.data.fee = { pandowei: "0", ptxwei: Number(i.data.gas_price) * Number(i.receipt.GasUsed) };

            //   i.symbol = 'loading..'
            //   i.data.type = i.type
            //   txbody.push(i);
            // }


          }

          let uniqueSet = new Set(txbody.map(JSON.stringify));
          let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

          for (let i of uniqueArray) {
            i.data.fee = Object.fromEntries(
              Object.entries(i.data.fee).map(([k, v]) => [k.toLowerCase(), v])
            );

            i.data.inputs[0].coins = Object.fromEntries(
              Object.entries(i.data.inputs[0].coins).map(([k, v]) => [
                k.toLowerCase(),
                v,
              ])
            );

            i.data.outputs[0].coins = Object.fromEntries(
              Object.entries(i.data.outputs[0].coins).map(([k, v]) => [
                k.toLowerCase(),
                v,
              ])
            );

            this.setState({ transactionData: uniqueArray });
          }
          // this.getSymbol();
          let pendingTX = [];
          if (sessionStorage.getItem("pendingTX")) {
            pendingTX = JSON.parse(sessionStorage.getItem("pendingTX"));
          }
          for (let i of pendingTX) {
            i.timestamp = Math.floor(Date.now() / 1000);
            this.state.transactionData.unshift(i);
          }
          let asd = setInterval(() => {
            for (let i of pendingTX) {
              apiService.getHashDetails(i.hash).then((data) => {
                if (data.body.status == "finalized") {
                  this.state.transactionData.shift();
                  pendingTX.shift();
                  sessionStorage.setItem(
                    "pendingTX",
                    JSON.stringify(pendingTX)
                  );
                  this.getTranscton();
                  document.getElementById("Refresh1").click();
                  clearInterval(asd);
                }
              });
            }
          }, 5000);
        } else {
        }
        this.setState({ transactionLoading: false });
      })
      .catch(() => {
        this.setState({ transactionLoading: false });
      });



  }
}

export default withTranslation()(WalletApp);
