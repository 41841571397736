import axios from 'axios';
import config from '../Config';


const BASE_URL = config.BASE_URL
const explorerApiUri = config.explorerApiUri
const rtSevicesApiUri = config.rtSevicesApiUri

const DEFAULT_HEADERS = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

//
//Helpers
//

export function isResponseSuccessful(response) {
    let { status } = response;

    return (status === 200 || status === 201 || status === 202 || status === 204);
}

function objectToQueryString(object) {
    if (!object) {
        return "";
    }

    let queryString = Object.keys(object).map(function (key) {
        let val = object[key];
        if (val) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
        }
    }).join('&');

    if (queryString.length > 0) {
        return "?" + queryString;
    }
    else {
        return "";
    }
}

//
//Builders
//

function buildHeaders(additionalHeaders) {
    //TODO inject auth headers here...
    return Object.assign(DEFAULT_HEADERS, additionalHeaders);
}

function buildURL(path, queryParams) {
    let url = null;

    if (path.startsWith("http://") || path.startsWith("https://")) {
        url = path + objectToQueryString(queryParams);
    }
    else {
        url = BASE_URL + path + objectToQueryString(queryParams);
    }

    return url;
}

function sendRequest(path, method, additionalHeaders, queryParams, body) {
    let url = buildURL(path, queryParams);
    let headers = buildHeaders(additionalHeaders);

    let opts = {
        method: method,
        headers: headers,
    };

    if (body) {
        opts['body'] = JSON.stringify(body);
    }

    return fetch(url, opts);
}


//
//Convenience requests
//

function GET(path, headers, queryParams) {
   console.clear();
    return sendRequest(path, "GET", headers, queryParams);
}

function PUT(path, headers, queryParams, body) {
     console.clear();
    return sendRequest(path, "PUT", headers, queryParams, body);
}

function POST(path, headers, queryParams, body) {
    console.clear();
    return sendRequest(path, "POST", headers, queryParams, body);
}

function DELETE(path, headers, queryParams, body) {
     console.clear();
    return sendRequest(path, "DELETE", headers, queryParams, body);
}

export default class Api {

    //
    //Wallet
    //

    static fetchWallet(address, queryParams) {
        //let path = `wallet/${address}`;
        //return GET(path, null, queryParams);
    }

    //
    //Transactions
    //

    static createTransaction(body, queryParams) {
        let path = `tx`;
        return POST(path, null, queryParams, body);
    }

    static fetchTransaction(transactionID, queryParams) {
        let path = `tx/${transactionID}`;
        return GET(path, null, queryParams);
    }

    static fetchTransactions(address, queryParams) {
        let path = `tx/${address}/list`;
        return GET(path, null, queryParams);
    }

    //
    //Sequence
    //

    static fetchSequence(address, queryParams) {
        let path = `sequence/${address}`;
        return GET(path, null, queryParams);
    }

    //
    //Stakes
    //

    static fetchStakes(address) {
        return axios.get(`${explorerApiUri}/stake/${address}`).then(data => data.data);

    }


    static getWalletBalance(address) {
        return axios.get(`${explorerApiUri}/account/update/${address}`).then((data) => data.data)
    }
    static fetchRewards(address) {
        return axios.get(`${explorerApiUri}/rewards/${address}`).then(data => data.data);

    }

    static getTransactionHistory(address) {//to be changed
        return axios.get(`${explorerApiUri}/accountTx/${address}?types=[2,7]&type=-1&pageNumber=1&limitNumber=50&isEqualType=true`).then((data) => data.data)
    }
    static getHashDetails(address) {//to be changed
        return axios.get(`${explorerApiUri}/transaction/${address}`).then((data) => data.data)
    }

    static getSequence(address) {
        return axios.get(`${explorerApiUri}/account/update/${address}`).then(data => data.data);

    }

    static getStakeType(address) {
        return axios.get(`${explorerApiUri}/staketype/${address}`).then(data => data.data);

    }
    static getWalletSummary(address) {
        return axios.get(`${explorerApiUri}/getrametron_summery?address=${address}`).then(data => data.data);

    }
    static getTokenSummary(address) {
        return axios.get(`${explorerApiUri}/tokenSummaries?addressList=${JSON.stringify(address)}`).then(data => data.data);

    }
    static getTokenAccountSummary(contract,address) {
        return axios.get(`${explorerApiUri}/walletTokenSummary?contract=${contract}&address=${address}`).then(data => data.data);

    }
    static pnc2tokentx(address) {
        return axios.get(`${explorerApiUri}/account/tokenTx/${address}?type=PNC-20&pageNumber=1&limit=20`).then(data => data.data);
    }
    static pnc721tokentx(address) {
        return axios.get(`${explorerApiUri}/account/tokenTx/${address}?type=PNC-721&pageNumber=1&limit=20`).then(data => data.data);
    }

    static priceapi(contract,address) {
        return axios.get(`${explorerApiUri}/lbank/price`).then(data => data.data);

    }

    static getAllPublicNodeaBasedOnType(type) {
        let finalType = "";
        if(type == 2)
        {
            finalType = 'RT Enterprise'
        }
        else if(type == 3)
        {
            finalType = 'RT Pro'
        }
        else if(type == 4)
        {
            finalType = 'RT Lite'
        }
        else if(type == 5)
        {
            finalType = 'RT Mobile'
        }
        else{
            return 'nothing'
        }
        return axios.get(`${explorerApiUri}/RTpool_allDetails?type=${finalType}`,{ headers: { 'Authorization': config.refreshToken } }).then(data => data.data);
    }

    //
    //Smart Contract
    //

    static callSmartContract(body, queryParams) {
        let path = "smart-contract/call";

        return POST(path, null, queryParams, body);
    }


    static executeSmartContract(body, queryParams) {
        let path = "tx";

        return POST(path, null, queryParams, body);
    }

    static checkBalance(wallet) {
        const body = {
            jsonrpc: "2.0",
            method: "pando.GetAccount",
            params: [
                { address: wallet }
            ],
            id: 1
        }
        return axios.post(BASE_URL + `/node`, body);

    }

    static createTranscation(body) {
        const body1 = {
            "jsonrpc": "2.0",
            "method": "pandocli.Send",
            "params": [body],
            "id": 1
        }
        return axios.post(BASE_URL + `/cli`, body1)
    }

    static callSmartContracts(byteCode) {

        const body = {
            "jsonrpc": "2.0",
            "method": "pando.CallSmartContract",
            "params": byteCode,
            "id": 1
        }
        return axios.post(BASE_URL + '/node', body).then((data) => data.data);
    }

    static sendTransaction(byteCode) {
        const body = {
            "jsonrpc": "2.0",
            "method": "pando.BroadcastRawTransactionAsync",
            "params": [{ "tx_bytes": byteCode }],
            "id": 1
        }
        return axios.post(BASE_URL + '/node', body).then((data) => data.data);
    }

}
